<template>
    <a href="#" @click.prevent="onOpenInspectionList">{{ $t('inspection.inspection_history') }}</a>
</template>

<script>
export default {
  name: "Inspection-Link",
  props: {
    assetId: String,
    assetType: String,
    inspectionType: String
  },
  methods: {
    onOpenInspectionList() {
      const name = `${this.assetType}-${this.inspectionType}-list`
      this.$router.push({name: name, params: {aid: this.assetId}})
    }
  }
}
</script>

<style scoped>

</style>
