<template>
  <div class="md-layout-item md-size-50 md-large-size-100 md-small-size-100">
    <div class="md-layout md-size-100 md-alignment-center-right">
      <div class="md-layout-item">
        <InspectionLink :asset-id="bid" :inspection-type="'intervention'" :asset-type="'bridge'"/>
      </div>
    </div>
    <form>
      <div class="">
        <div :style="{'padding-left':0, 'padding-right':0}" class="">
          <md-card>
            <md-card-header>
              <div class="md-headline card-title"> {{ $t("bridges.intervention_general") | UpCase }}</div>
            </md-card-header>
            <md-card-content>
              <md-field
                  :class="[
                  { 'md-valid': !errors.has('bridgeElement') && touched.bridgeElement },
                  { 'md-error': errors.has('bridgeElement') }
                  ]">
                <label>
                  {{ $t(`bridges.element`) }}
                </label>
                <md-select
                    v-model="bridgeElement"
                    v-validate="modelValidations.bridgeElement"
                    :data-vv-name="'bridgeElement'"
                    :disabled="isReadOnly"
                >
                  <md-option v-for="element in elementList" :key="element.id" :value="element.name">
                    {{ element.name }}
                  </md-option>
                </md-select>
                <slide-y-down-transition>
                  <md-icon v-show="errors.has('bridgeElement')" class="error">close</md-icon>
                </slide-y-down-transition>
              </md-field>
              <div>
                <md-datepicker
                    v-model="intervention_date"
                    md-immediately
                    name="intervention_date">

                  <label>
                    {{ $t(`bridges.intervention_date`) }}
                  </label>
                </md-datepicker>

              </div>
              <md-field
                  :class="[
                  { 'md-valid': !errors.has('typeOfIntervention') && touched.typeOfIntervention },
                  { 'md-error': errors.has('typeOfIntervention') }
                  ]">
                <label>
                  {{ $t(`bridges.typeOfIntervention`) }}
                </label>
                <md-select
                    v-model="typeOfIntervention"
                    v-validate="modelValidations.typeOfIntervention"
                    :data-vv-name="'typeOfIntervention'"
                    :disabled="isReadOnly"
                >
                  <md-option v-for="element in interventionTypes" :key="element" :value="element">
                    {{ $t(`bridges.intervention_type_${element}`) }}
                  </md-option>
                </md-select>
                <slide-y-down-transition>
                  <md-icon v-show="errors.has('typeOfIntervention')" class="error">close</md-icon>
                </slide-y-down-transition>
              </md-field>
              <md-field
                  :class="[
                  { 'md-valid': !errors.has('comment') && touched.comment },
                  { 'md-error': errors.has('comment') }
                  ]">
                <label>
                  {{ $t(`bridges.comment`) }}
                </label>
                <md-input
                    v-model="comment"
                    v-validate="modelValidations.comment"
                    data-vv-name="roadName"
                    type="text"
                    :disabled="isReadOnly"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon v-show="errors.has('comment')" class="error">close</md-icon>
                </slide-y-down-transition>
              </md-field>
            </md-card-content>
          </md-card>
          <md-card>
            <md-card-header>
              <div class="md-headline card-title"> {{ $t("bridges.intervention_photos") | UpCase }}</div>
            </md-card-header>
            <div class="toolbar-icon">
              <div v-if="!isReadOnly" :title="$t('button.add')" @click.prevent="selectPhotoFiles"><i class="fas fa-plus-circle"></i></div>
            </div>
            <md-card-content>
              <div v-for="(row, index) in Math.round(photoCatalog.length/2)" :key="row"
                   class="md-layout md-layout-item md-size-100 inspection-photo-catalog">
                <div :style="{background: `url(${photoCatalog[index*2].src})`}"
                     class="md-layout md-layout-item md-size-50 md-large-size-100 inspection-image">

                </div>
                <div v-if="photoCatalog.length > index*2+1"
                     :style="{background: `url(${photoCatalog[index*2+1].src})`}"
                     class="md-layout md-layout-item md-size-50 md-large-size-100 inspection-image">
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
        <md-card-actions md-alignment="left" v-if="mode!=='read'">
          <md-button
              class="md-success"
              native-type="submit"
              @click.native.prevent="validate"
          >{{ $t(`button.save`) }}
          </md-button
          >
          <md-button
              class="md-default"
              @click.native.prevent="back"
          >{{ $t("button.back") }}
          </md-button
          >
        </md-card-actions>
      </div>
    </form>
    <input
        id="photos"
        ref="photos"
        :accept="'image/*'"
        class="custom-file-input"
        type="file"
        @change="addPhotos($event)"
    />
  </div>
</template>

<script>
import {SlideYDownTransition} from "vue2-transitions"
import Swal from "sweetalert2"
import InspectionLink from "@/components/Inspection-Link";

export default {
  name: "bridgeInterventionForm",
  components: {
    SlideYDownTransition,
    InspectionLink
  },
  props: {
    mode: String
  },
  watch: {
    ['bridge.bridgeElement']() {
      this.touched.bridgeId = true
    },
    ['bridge.typeOfIntervention']() {
      this.touched.typeOfIntervention = true
    },
    ['bridge.comment']() {
      this.touched.comment = true
    },
  },

  data() {
    let now = new Date()
    return {
      bridgeElement: null,
      typeOfIntervention: null,
      comment: "",
      intervention_date: now,
      interventionTypes: ["maintenance", "repair", "replacement_or_renewal"],
      elementList: [],
      photoCatalog: [],
      modelValidations: {
        bridgeElement: {
          required: true
        },
        typeOfIntervention: {
          required: true
        },
        comment: {
          required: true
        },
        intervention_date: {
          required: true,
          date_format: 'yyyy-MM-dd'
        },
      },
      touched: {
        bridgeElement: false,
        typeOfIntervention: false,
        comment: false,
        intervention_date: false
      },
      bid: this.$route.params.bid,
      iid: this.$route.params.iid ? this.$route.params.iid : 'last',
    }
  },
  async created() {

    const elements = await this.$store.dispatch("LOAD_BRIDGE_ELEMENT_LIST")
    this.elementList = [...elements]

    if (this.mode === 'read') {
      const intervention = await this.$store.dispatch('LOAD_BRIDGE_INTERVENTION_DATA', {
        bridge_id: this.bid, inspection_id: this.iid
      })
      this.bridgeElement = intervention.bridge_element
      this.intervention_date = intervention.inspection_date
      this.typeOfIntervention = intervention.type_of_intervention
      this.comment = intervention.comment

      const payload = {
        inspection_id: this.iid,
        inspection_type: 'interventions',
        inspection_asset_type: 'bridge',
        inspection_asset_id: this.bid
      }
      //const images = await this.$store.dispatch("LOAD_BMS_INSPECTION_FILES", payload)

    }

  },
  filters: {
    UpCase: function (value) {
      return value.toUpperCase()
    }
  },
  methods: {
    selectPhotoFiles() {
      this.$refs.photos.click()
    },
    addPhotos(evt) {
      const dropFiles = [...evt.target.files]
      dropFiles.forEach((file) => {
        const src = URL.createObjectURL(file)
        this.photoCatalog.push({file, src})
      })
    }
    ,
    onFilesList() {
      this.$router.push({name: "bridge-files", params: {bid: this.bid}})
    }
    ,
    back() {
      this.$router.go(-1)
    }
    ,

    validate() {

      const alert = {
        type: 'success',
        text: this.$t(`bridge.record_was_added`),
        footer: ''
      }


      this.$validator.validateAll().then(async isValid => {
            if (isValid) {
              const data = {
                bridge_element: this.bridgeElement,
                type_of_intervention: this.typeOfIntervention,
                comment: this.comment,
                inspection_date: this.intervention_date
              }
              const bridge_id = this.bid
              try {
                const inspId = await this.$store.dispatch("ADD_BRIDGE_INTERVENTION_INSPECTION", {bridge_id, data})
                for (const photo of this.photoCatalog) {
                  if (photo.file) {
                    let formData = new FormData()
                    formData.append('files', photo.file)
                    formData.append('inspection_type', 'interventions')
                    formData.append('inspection_id', inspId)
                    formData.append('inspection_asset_type', 'bridge')
                    formData.append('inspection_element', this.bridgeElement)
                    formData.append('inspection_asset_id', this.bid)
                    try {
                      await this.$store.dispatch('ADD_INSPECTION_FILE', formData)
                    } catch (err) {
                      alert.type = 'error'
                      alert.text = this.$t(`bridge.photo_was_not_added`)
                      alert.footer = err.response ? err.response.data : this.$t(err.message)
                      Swal.fire(alert)
                    }
                  }
                }

                Swal.fire(alert).then(() => {
                  this.$nextTick(() => this.$validator.reset())
                })
              } catch
                  (err) {
                alert.type = 'error'
                alert.text = this.$t(`bridge.record_was_not_added`)
                //alert.footer = this.$t(err)
                Swal.fire(alert)
                throw err
              }
            } else {
              alert.type = 'error'
              alert.text = this.$t(`messages.you_must_fill_in_all_required_fields`)
              Swal.fire(alert)
            }
          }, (err) => {
            alert.type = 'error'
            alert.text = this.$t(`bridge.record_was_not_added`)
            console.log(err)
            Swal.fire(alert)
          }
      )
    }
  },
  computed: {
    isReadOnly() {
      return this.mode === 'read'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.custom-file-input {
  display: none;
}

.inspection-photo-catalog {
  display: flex;
  justify-items: center;
  padding: 5px 0;

  .inspection-image {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
    height: 300px;
  }
}


.card-title {
  font-size: 17px;
}

.md-card .md-card-actions {
  border: none;
}

.toolbar-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 25px;

  cursor: pointer;
  display: flex;

  div {
    padding-left: 10px;
    color: $gray-color;

    &:hover {
      color: $brand-primary;
    }
  }
}
</style>
